<template>
  <v-card flat class="my-6 mx-9 rounded-lg" color="#f5f7f7">
    <v-row v-if="details.headers" class="pa-0 mx-4">
      <v-col cols="6" class="font-weight-black"
        ><span class="pl-1">{{ details.headers[0] }}</span></v-col
      >
      <v-col cols="6" class="font-weight-black"
        ><span class="pl-1">{{ details.headers[1] }}</span></v-col
      >
    </v-row>

    <div class="container">
      <div v-for="(item, index) in details.items" :key="index" class="d-flex">
        <div class="px-2 left text-overline grey--text">
          {{ item.text }}
        </div>
        <div class="px-2 right" :class="{ 'font-weight-black': item.bold }">
          {{ item.value }}
        </div>
      </div>
    </div>

    <div v-if="details.footer">
      <v-divider class="my-4"></v-divider>

      <div class="ml-2">
        <v-btn icon>
          <v-icon color="black">mdi-link</v-icon>
        </v-btn>

        <v-btn small text color="blue">{{ details.footer }}</v-btn>
      </div>
    </div>
  </v-card>
</template>

<script>
export default {
  props: ["details"]
};
</script>

<style lang="scss" scoped>
.box {
  box-sizing: border-box;
  width: 48%;
  border: 1px solid red;
}

.container {
  display: grid;
  grid-template-columns: repeat(auto-fill, calc(50% - 20px));
  justify-content: space-around;
}

.left {
  width: 30%;
}

.right {
  width: 60%;
}
</style>
